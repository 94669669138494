<!--
 * @Description: 页面配置
 * @Autor: WangYuan
 * @Date: 2021-09-22 17:33:51
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-01-18 16:10:43
-->
<template>
  <div class="p10">
    <config-item label='背景色'>
      <config-color-picker v-model="project.config.backgroundColor"></config-color-picker>
    </config-item>
    <config-item label='是否开启首页配置'>
      <el-switch
        v-model="project.config.isOpen"
        inactive-value="0"
        active-value="1">
      </el-switch>
    </config-item>
    <config-item label='主题'>
      <el-select size="small" v-model="project.config.theme" placeholder="请选择主题">
        <el-option label="主题1" value="theme1"></el-option>
        <el-option label="主题2" value="theme2"></el-option>
      </el-select>
    </config-item>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PageConfig",
  computed: {
    ...mapGetters(["project"]),
  },
};
</script>

<style scoped lang="scss">
  .p10 {

    ::v-deep div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .w70 {
        width: auto!important;
      }

      .flex-1 {
        flex: inherit;
      }
    }
  }
</style>
