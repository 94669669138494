<!--
 * @Description: 页面构建
 * @Autor: WangYuan
 * @Date: 2021-05-19 09:49:33
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-01-11 20:01:59
-->
<template>
  <div class="build">
    <control />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Control from "@/components/Control";
import { getDiyDataById, getDiyCfgList } from "@/api/project";

export default {
  components: {
    Control,
  },

  data () {
    return {
      configList: []
    }
  },

  methods: {
   ...mapMutations(["setProject"]), 
  },

  async created() {
    const configList = await getDiyCfgList()
    this.$store.commit('setProjectList', configList.data)
    if (configList.data.length) {
      const currProjectId = configList.data.filter(v => v.is_checked === 1).length ? configList.data.filter(v => v.is_checked === 1)[0] : configList.data[configList.data.length - 1]
      const { status, data: { diy_data, project_id } = {} } = (await getDiyDataById({ project_id: currProjectId.project_id })) || {}
      if (status === 200) {
        const project = diy_data && JSON.parse(diy_data)
        this.setProject({
          ...project,
          project_id
        })
      } else {
        this.$message.error('获取diy数据失败')
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.build {
  height: 100vh;
  background: #f7f8fa;
  }
</style>
